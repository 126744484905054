import {createStore} from 'vuex'
import createPersistedState from "vuex-persistedstate";

export default createStore({
    state: {
        userData: Array,
        permission: String,
        menu: Array,
        socket_widget: Function,
        notifyNewAttendaceOrMenssageChat: Boolean,
        notifyNewTickt: Boolean,
        phoneAttendanceData:String
    },
    getters: {},
    mutations: {
        setUserdata(state, payload) {
            state.userData = payload
        },
        setPermission(state, payload) {
            state.permission = payload
        },
        setMenu(state, payload) {
            state.menu = payload
        },
        setSocketWidget(state, payload) {
            state.socket_widget = payload
        },
        setNotifyNewAttendaceOrMenssageChat(state, payload) {
            state.notifyNewAttendaceOrMenssageChat = payload
        },
        setNotifyNewTicket(state, payload) {
            state.notifyNewTickt = payload
        },
        setStatus(state, payload) {
            state.userData.id_status_atividade_usuario = payload
        },
        setPauseMotive(state, payload) {
            state.userData.motivo_pausa = payload
        },
        setPhoneAttendanceData(state,payload){
            state.phoneAttendanceData = payload
        }
    },
    actions: {},
    modules: {},
    plugins: [
        createPersistedState()
    ],
})
