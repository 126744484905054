<template>
  <div class="layout flex row">
    <audio hidden ref="audio_new_message">
      <source src="../assets/audio/audio_message.mp3" type="audio/mpeg">
    </audio>
    <audio hidden ref="audio_new_ticket">
      <source src="../assets/audio/sound_new_ticket.wav" type="audio/mpeg">
    </audio>
    <div class="sidebar">
      <div class="header flex row justify-center">
        <q-list>
          <q-item clickable class="q-mb-md q-pt-md" :to="{ name: 'home' }">
            <q-item-section>
              <img src="@/assets/images/logo-cosmos.svg" width="32" height="32"/>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
      <div class="body">
        <q-list>
          <div v-for="(item, index) in store.state.menu" :key="item.id" class="flex row justify-center body-menu-item">
            <q-item clickable :to="{ name: item.modulo }" @click="unAccessPhoneView">
              <q-item-section>
                <q-icon :name="item.icone" color="grey-5" size="1.5rem">
                  <q-badge floating rounded color="red" style="min-height: 8px" v-if="item.modulo === 'chat' && notifyNewAttendaceOrMenssageChat"/>
                  <q-badge floating rounded color="red" style="min-height: 8px" v-if="item.modulo === 'ticket' && notifyNewAttendaceOrMenssageTicket"/>
                </q-icon>
              </q-item-section>
              <q-tooltip class="text-body" anchor="center left" self="center right" :offset="[2, 2]">
                {{ item.label_menu }}
              </q-tooltip>
            </q-item>

            <!-- <q-item v-if="(index + 1) == store.state.menu.length" @click="accessPhoneView" clickable >
              <q-item-section>
                <q-icon name="phone" color="grey-5" size="1.5rem"></q-icon>
              </q-item-section>
              <q-tooltip class="text-body" anchor="center left" self="center right" :offset="[2, 2]">
                Telefone
              </q-tooltip>
            </q-item> -->

          </div>
        </q-list>
      </div>
      <div class="footer">
        <div class="footer-menu-item q-pl-xs">
          <q-item clickable>
            <div class="row col-10">
              <q-btn class="q-pa-none">
                <q-img src="@/assets/images/person-avatar.svg" width="1.8rem"
                       :style="`border: 2px solid ${color_avatar_status_corrent}; border-radius: 22px`"/>

                <q-tooltip v-if="pauseMotive" class="text-body" style="background: yellow; border-radius: 0px 5px 5px 0px; color:black" anchor="center left" self="center right" :offset="[12, 2]">
                  {{ pauseMotive }}
                </q-tooltip>
                <q-menu class="card-list-status" dark anchor="top right" self="top left">
                  <q-list style="min-width: 200px">
                    <q-item class="flex row justify-between" @click="changeStatus(1, 'green')"
                            clickable v-close-popup>
                      <label>Disponível</label>
                      <div>
                        <q-badge rounded color="green"/>
                      </div>
                    </q-item>
                    <q-item class="flex row justify-between" clickable>
                      <label>Pausa</label>
                      <div>
                        <q-badge rounded color="yellow"/>
                      </div>
                      <q-menu anchor="bottom middle" self="bottom left" fit>
                        <q-list>
                          <div v-for="item in listMotivePause"
                               :key="item.id_motivo_pausa_status_atividade_usuario">
                            <q-item clickable v-close-popup
                                    @click="changeStatus(2, 'yellow', item.id_motivo_pausa_status_atividade_usuario)">
                              <q-item-section>
                                <q-item-label>{{ item.descricao }}</q-item-label>
                              </q-item-section>
                            </q-item>
                          </div>
                        </q-list>
                      </q-menu>
                    </q-item>
                    <q-item class="flex row justify-between" @click="changeStatus(3, 'red')"
                            clickable v-close-popup>
                      <label>Indisponível</label>
                      <div>
                        <q-badge rounded color="red"/>
                      </div>
                    </q-item>
                    <q-item class="flex row justify-between" @click="openModal()"
                            clickable v-close-popup>
                      <label>Alterar senha</label>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </div>
          </q-item>
        </div>
        <q-dialog v-model="alerta">
      <q-card style="width: 70vh;" class="q-pb-sm">
        <q-card-section>
          <div class="text-h6">Alterar a senha</div>
        </q-card-section>

        <q-card-section>
          <q-input outlined v-model="oldPassword" label="senha antiga" :type="isPassword? 'password' : 'text'" class="q-pb-md">
            <template v-slot:append>
              <q-icon
                :name="isPassword ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPassword = !isPassword"
              />
            </template>
          </q-input>
          <q-input outlined v-model="newPassword" label="nova senha" :type="isNewPassword? 'password' : 'text'" class="q-pb-md" @focus="verifyPassword">
            <template v-slot:append>
              <q-icon
                :name="isNewPassword ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isNewPassword = !isNewPassword"
              />
            </template>
          </q-input>
          <q-input outlined v-model="passwordVerification" label="repita a senha" :rules="rules_empty"  :type="isPasswordVerification? 'password' : 'text'" class="q-pb-md">
            <template v-slot:append>
              <q-icon
                :name="isPasswordVerification ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPasswordVerification = !isPasswordVerification"
              />
            </template>
          </q-input>
        </q-card-section>

        <q-card-actions v-if=" newPassword == passwordVerification && oldPassword != ''" align="right">
          <q-btn flat label="Atualizar" color="primary" @click="updatePassword"/>
        </q-card-actions>
      </q-card>
    </q-dialog>
        <div class="q-pl-sm">
          <q-item clickable class="q-pb-md" @click="logout">
            <q-item-section>
              <q-icon name="o_logout" color="grey-5" size="1.5rem"/>
            </q-item-section>
            <q-tooltip class="text-body" anchor="center left" self="center right" :offset="[0, 2]">
              Sair
            </q-tooltip>
          </q-item>
        </div>
      </div>
    </div>

    <div class="internal-chat shadow-3 chat-interno" v-show="showInternalChat">
      <div class="col-12 row flex q-pr-sm header shadow-1">
        <div class="col-11 q-pl-md row flex justify-center items-center">
          <label class="text-white">Chat interno</label>
        </div>

        <div class="col-1 row flex justify-center">
          <q-btn flat round color="white" icon="close" @click="showInternalChat = !showInternalChat"/>
        </div>

      </div>
      
      <div class="col-12">
        <SideInternalChat 
          @isnewmessage="warninIcon" 
          >
        </SideInternalChat>
      </div>
     
    </div>
    <q-btn size="1rem" v-show="!showInternalChat" round icon="chat" @click="showInternalChat = !showInternalChat"
           class="q-ml-md btn-internal-chat">
           <div v-show="warningSign">
              <q-badge class="btn-internal-notification" rounded color="orange"/>
            </div>
    </q-btn>

    <div class="content flex row">
      <!-- <PhonePage :config="config" @show_call="accessPhoneView"/> -->

      <router-view v-show="!phoneView" v-slot="{ Component }">
        <component :is="Component"/>

      </router-view>
    </div>
  </div>


</template>
<script setup>
import {useQuasar} from 'quasar';
import {ref, onMounted, computed} from 'vue';
import {useStore} from 'vuex';
import {useRouter, useRoute} from 'vue-router';
import ChatProvider from '@/provider/ChatProvider';
import WidgetProvider from '@/provider/WidgetProvider';
import UserServices from '@/services/UserServices';
import SettingServices from '@/services/SettingServices';
import SideInternalChat from '@/components/chat/SideInternalChat.vue';
import TelephonyMain from '@/components/web-rtc/TelephonyMain.vue';
import PhonePage from '@/pages/phone/PhonePage.vue';

let phoneView = ref(false);

let config = ref({
  server: "dac.wtfapps.dev.br",
  ramal: "6002",
  password: "81f15cefcdf7606584cde0ca32d569e8",
  inbound_automatic: true,
  outbound: true,
  out_screen: true
});

let alerta = ref(false);
let rules_empty = [val => val == newPassword.value && val.length > 1 || 'As senhas tem que ser iguais'];
const $q = useQuasar();
const store = useStore();
const audio_new_message = ref();
const audio_new_ticket = ref();

const _chatProvider = new ChatProvider();

const _widgetProvider = new WidgetProvider();
const _userServices = new UserServices();
let _settingServices = new SettingServices("reason-state-pause");
_chatProvider.start();
_widgetProvider.start();
let pauseMotive= ref('');
let notifyNewAttendaceOrMenssageChat = computed(() => store.state.notifyNewAttendaceOrMenssageChat);
let notifyNewAttendaceOrMenssageTicket = computed(() => store.state.notifyNewTickt);
let showInternalChat = ref(false);
let listMotivePause = ref([]);
let warningSign = ref(false);
let oldPassword = ref("")
let newPassword = ref("")
let passwordVerification = ref("")
let isPassword = ref(true);
let isPasswordVerification = ref(true);
let isNewPassword = ref(true);
let color_avatar_status_corrent = ref("");
const router = useRouter();
const route = useRoute();

function accessPhoneView(){
  phoneView.value = true;
  config.value.out_screen = false;
}

function unAccessPhoneView(){
  phoneView.value = false;
  config.value.out_screen = true;
}

function openModal(){
  alerta.value = true
}


function _findPauseMotive(motivePause){
  let motiveFound = listMotivePause.value.find((motive)=>motive.id_motivo_pausa_status_atividade_usuario == motivePause);
  pauseMotive.value = motiveFound.descricao;
  store.commit('setPauseMotive', {
    title: motiveFound.descricao,
    created_at: new Date()
  });
}

function verifyPassword(){
  if(oldPassword.value != passwordVerification.value){ 

  }
}

async function updatePassword(){
  $q.loading.show()
  let passwords = {
    newPassword: newPassword.value,
    oldPassword: oldPassword.value
  }
  await _userServices.updatePassword(store.state.userData.id_usuario, passwords).then(response=>{
    if (response) {    
      $q.notify({
        message: `Senha alterada com sucesso.`,
        color: "green-8",
        position: "top"
      })
    } else {
      $q.notify({
        message: `Não foi possível alterar a senha.`,
        color: "red-8",
        position: "top"
      })
    }
    $q.loading.hide()
  })
}


async function changeStatus(code_status, color_status, motivePause = 0) {
  $q.loading.show()
  await _userServices.changeStatus(code_status, motivePause).then((response) => {
    if (response) {
      store.commit('setStatus', code_status);
      motivePause != 0 ? _findPauseMotive(motivePause) : pauseMotive.value = '' 
      $q.notify({
        message: `Status alterado com sucesso.`,
        color: "green-8",
        position: "top"
      })
      color_avatar_status_corrent.value = color_status;
    } else {
      $q.notify({
        message: `Não foi possível alterar o status.`,
        color: "red-8",
        position: "top"
      })
    }
    $q.loading.hide()
  })
}

function _getLogStatus() {
  $q.loading.show();
  _userServices.getLogStatus(store.state.userData.id_usuario).then((response) => {
    if (response) {
      switch(response[0].id_status_atividade_usuario){
        case 1:
          store.commit('setStatus', 1);
          color_avatar_status_corrent.value = "green";
          pauseMotive.value = ''
          break;
        case 2:
          store.commit('setStatus', 2);
          color_avatar_status_corrent.value = "yellow";
          pauseMotive.value = response[0].descricao;
          store.commit('setPauseMotive', {
            title: response[0].descricao,
            created_at: response[0].data_atividade,
          });
          break;
        case 3:
          store.commit('setStatus', 3);
          color_avatar_status_corrent.value = "red";
          pauseMotive.value = ''
          break;
        default:
          color_avatar_status_corrent.value = "red";
      }
    }
    $q.loading.hide()
  });
}

function _getMotivePause() {
  $q.loading.show();
  _settingServices.getAllActive(store.state.userData.id_empresa).then((response) => {
    listMotivePause.value = response;
    $q.loading.hide()
  });
}

function warninIcon(isNewMessage){
  warningSign.value = isNewMessage
}

async function logout() {
  //Status 3 corresponde a inativo
  $q.loading.show();
  await changeStatus(3, 'red');
  _userServices.Logout().then((response) => {
    if (response) {
      $q.loading.hide()
      localStorage.clear();
      router.push({"name": "/"});
      _chatProvider.disconnect();
    } else {
      $q.loading.hide()
      $q.notify({
        message: `Não foi possível fazer o logout, tente novamente mais tarde.`,
        color: "red-8",
        position: "top"
      })
    }
  });
}

function _getMessagesOfCustomerChat() {
  _chatProvider.connection.on("ReceiveMessage", (conversationId, message) => {
    if (message.cliente) {
      if(route.name !== "chat") {
        store.commit('setNotifyNewAttendaceOrMenssageChat', true);
      }
      _audioNewMessage();
    }
  });
}

function _newAttendancesChat() {
  _chatProvider.connection.on("NotifyNewAttendances", (conversationId, attendance) => {
    if(route.name !== "chat"){
      store.commit('setNotifyNewAttendaceOrMenssageChat', true);
    }
    _audioNewMessage();
  });
}

function _newAttendancesTickets() {
  _chatProvider.connection.on("NotifyNewTickets", (ticket) => {
      store.commit('setNotifyNewTicket', true);
    _audioNewTicket();
  });
}

function _audioNewMessage() {
  audio_new_message.value.play();
}

function _audioNewTicket() {
  audio_new_ticket.value.play();
}

onMounted(() => {
  _getLogStatus();
  _getMotivePause();
  _getMessagesOfCustomerChat();
  _newAttendancesChat();
  _newAttendancesTickets();

  store.commit('setNotifyNewAttendaceOrMenssageChat', false);
  store.commit('setNotifyNewTicket', false);

  _chatProvider.connection.on("NotifyAboutNewPhoneCall",(dac_id, caller_number)=>{
    store.commit('setPhoneAttendanceData', caller_number);
    let phoneWindowPopUp = router.resolve({name:'call', params:{id: dac_id}, props:true});
    window.open(phoneWindowPopUp.href).focus();
  })

  _chatProvider.connection.on("DisconectUser", () => {
    $q.loading.hide()
    localStorage.clear();
    _chatProvider.disconnect();

    $q.dialog({
      title: 'Atenção',
      message: 'Você foi desconectado pois seu usuário está logado em outra sessão.',
      persistent: true
    }).onOk(() => {
      router.push({"name": "/"});
    });
  });
})
</script>
<style lang="scss" scoped>
@media (max-width: 600px) {
  .chat-interno {
    width: 80%;
  }
}

@media (min-width: 600px) {
  .chat-interno {
    width: 20%;
  }
}

.layout {
  height: 100%;
}

.internal-chat {
  position: absolute;
  bottom: 0px;
  right: 10px;
  z-index: 1;
  // width: 20%;
  height: 67vh;
  background-color: white;
  border-radius: 4px 4px 0px 0px;

  .header {
    height: 7vh;
    border-radius: 4px 4px 0px 0px;
    background: linear-gradient(90deg, #1d2c5a 3.83%, #002a6a);
  }
}

.btn-internal-chat {
  position: absolute;
  bottom: 10px;
  right: 23px;
  z-index: 1;
  background: #18254c;
  color: white;
}

.btn-internal-notification {
  position: absolute;
  bottom: 35px;
  right: 2px;
}

.content {
  width: 100%;
  position: absolute;
  padding-left: 3.1rem;
  height: 100%;
  background: linear-gradient(180deg, hsla(0, 0%, 100%, .2), hsla(0, 0%, 100%, 0)), radial-gradient(133.17% 32.16% at 59.58% 51.43%, rgba(237, 241, 250, .24) 0, rgba(237, 241, 250, .15) 100%);
  box-shadow: 4px 0 4px rgb(0 0 0 / 5%);
  backdrop-filter: blur(15px);
}

.card-list-status {
  background-color: #1d2c57 !important;
}

.sidebar {
  background: linear-gradient(103.23deg, #111930 11.08%, #18254c 92.08%);
  height: 100vh;
  width: 50px;
  z-index: 1;


  .body {
    max-height: 70%;
    overflow-y: auto;
    overflow-x: hidden;

    .body-menu-item {
      border-bottom: #cecece5c solid 1px;
    }

  }

  .footer {
    position: absolute;
    bottom: 0;
    left: -10px;

    .footer-menu-item {

      border-bottom: #cecece5c solid 1px;
    }
  }

  .btn-pause {
    size: 20px;
  }


}
</style>